import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function Blogs() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Blogs">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Blogs"
                    Subheader="Blogs"
                    bgimg={`${rpdata?.stock?.[1]}`}
                />
                <h2 className="text-center py-[100px]">Our Blogs</h2>
                
            </div>
        </BaseLayout>
    );
}

export default Blogs;